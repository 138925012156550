::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #00C6CE;
    border-radius: 50px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #099698;
  }


  
.inputHome{
  &:focus{
    border: 1px solid #00C2CB !important;
  }
}

.mobileHamburger{
  display: none;
  @media (max-width:1400px){
    display: block;
  }
}  